import mainApi from "./api.js";
import { fonts } from "./fonts.js";

async function getGoogleFontLinks(fontsData, format, site) {
  // const googleFontsResponse = await mainApi.getFonts({type:site});
  const googleFontsResponse = fonts.google;

  const fontLinks = [];
  const fontNames = fontsData.fonts.map((font) => font.fontFamily);

  for (const fontName of fontNames) {
    // const googleFont = googleFontsResponse.find(font => font.family === fontName);
    const googleFont = googleFontsResponse.find((font) => font === fontName);
    if (googleFont) {
      fontLinks.push({
        fontName,
        link: `https://fonts.google.com/specimen/${fontName.replace(/ /g, "+")}`,
      });
    } else {
      fontLinks.push({
        fontName,
        link: "",
      });
    }
  }

  // Генерируем содержимое файла в зависимости от формата
  const content = generateFontContent(fontLinks, fontsData.fonts, format);

  // Путь к файлу и формат файла зависят от параметра format
  const newPath = `${fontsData.path}/Fonts.${format}`;
  const saveResult = window.cep.fs.writeFile(
    newPath,
    content,
    window.cep.encoding.UTF8,
  );
  if (saveResult.err === 0) {
    console.log("Файл успешно сохранен:", newPath);
  } else {
    alert("Error saving file:", saveResult.err);
  }

  return fontLinks;
}

async function getAdobeFontsLinks(fontsData, format, site) {
  // const adobeFontsResponse = await mainApi.getFonts({type:site});
  const adobeFontsResponse = fonts.adobe;
  const fontLinks = [];
  const fontNames = fontsData.fonts.map((font) => font.fontFamily);

  for (const fontName of fontNames) {
    // const googleFont = adobeFontsResponse.find(font => font.name === fontName);
    const googleFont = adobeFontsResponse.find((font) => font === fontName);
    if (googleFont) {
      fontLinks.push({
        fontName,
        link: `https://fonts.adobe.com/fonts/${fontName.toLowerCase().replace(/ /g, "-")}`,
      });
    } else {
      fontLinks.push({
        fontName,
        link: "",
      });
    }
  }

  // Генерируем содержимое файла в зависимости от формата
  const content = generateFontContent(fontLinks, fontsData.fonts, format);

  // Путь к файлу и формат файла зависят от параметра format
  const newPath = `${fontsData.path}/Fonts.${format}`;
  const saveResult = window.cep.fs.writeFile(
    newPath,
    content,
    window.cep.encoding.UTF8,
  );
  if (saveResult.err === 0) {
    console.log("Файл успешно сохранен:", newPath);
  } else {
    alert("Error saving file:", saveResult.err);
  }

  return fontLinks;
}

function generateFontContent(fontLinks, fontsArray, format) {
  let content = "";
  let foundCount = 0;
  let notFoundCount = 0;
  let notFoundFonts = [];

  if (format === "rtf") {
    content =
      "{\\rtf1\\ansi\\deff0{\\fonttbl{\\f0 Arial;}}\n{\\f0 Download the free fonts used here:}\n\n";
    fontLinks.forEach(({ fontName, link }) => {
      const styles =
        fontsArray
          .find((font) => font.fontFamily === fontName)
          ?.fontStyles.join(", ") || "Styles not specified";
      content += `\\par\\b ${fontName}: \\b0 used styles: ${styles}: `;
      if (link) {
        content += `${link}\n`;
        foundCount++;
      } else {
        content += "url not found\n";
        notFoundFonts.push(fontName);
        notFoundCount++;
      }
    });
    content += "}";
  } else if (format === "txt") {
    content = "Download the free fonts used here:\n\n";
    fontLinks.forEach(({ fontName, link }) => {
      const styles =
        fontsArray
          .find((font) => font.fontFamily === fontName)
          ?.fontStyles.join(", ") || "Styles not specified";
      content += `${fontName}: used styles: ${styles}: `;
      if (link) {
        content += `${link}\n`;
        foundCount++;
      } else {
        content += "url not found\n";
        notFoundFonts.push(fontName);
        notFoundCount++;
      }
    });
  }

  let message = "";
  if (notFoundCount === 0) {
    message = "All font found: " + foundCount;
  } else if (foundCount > 0 && notFoundCount > 0) {
    message =
      "Font found: " +
      foundCount +
      ".\n\nFont not found: " +
      notFoundFonts.join(",\n");
  } else if (foundCount === 0) {
    message = "Font URL not found:\n\n" + notFoundFonts.join(",\n");
  }
  window.jsx.evalScript(
    "run($.ae.message(" + JSON.stringify(message) + "));",
    function (result) {},
  );

  return content;
}

export { getGoogleFontLinks, getAdobeFontsLinks };
