import JSZip from "jszip";

function fixExportMogrt(help) {
  window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
  window.jsx.evalScript(
    "run(exportMogrt(" + JSON.stringify({ message: true, help: help }) + "));",
    function (templateFolder) {
      if (templateFolder) {
        processMogrtFiles(templateFolder);
      } else {
        alert("Error when selecting a folder:");
      }

      function processMogrtFiles(filesJson) {
        try {
          // Парсинг переданного JSON в массив путей к файлам
          const data = JSON.parse(filesJson);
          const mgFiles = data.files;
          const placeholders = data.placeholders || false;

          mgFiles.forEach(async function (filePath) {
            const updatedContent = await fixMogrt(filePath, placeholders);
            const writeResult = window.cep.fs.writeFile(
              filePath,
              updatedContent,
            );

            // Опционально: проверка результата записи, если это необходимо
            if (writeResult.err !== window.cep.fs.NO_ERROR) {
              console.error(
                "Error writing to file:",
                filePath,
                writeResult.err,
              );
            }
          });
        } catch (error) {
          alert("Error processing files:", error);
        }
      }

      function fixHandler(json, placeholders) {
        const min = -300;
        const max = 300;

        const updateDimensions = (item, placeholder) => {
          if (item.width !== placeholder.width) {
            item.width = placeholder.width;
          }
          if (item.height !== placeholder.height) {
            item.height = placeholder.height;
          }
        };

        json.clientControls.forEach((controls) => {
          if (controls.fonteditinfo) {
            const info = controls.fonteditinfo;
            info.capPropFontEdit = true;
            info.capPropFontFauxStyleEdit = true;
            info.capPropFontSizeEdit = true;
          }

          // Проверка на "Line Spacing" для clientControls
          if (controls.uiName && controls.uiName.strDB) {
            controls.uiName.strDB.forEach((strObj) => {
              if (strObj.str && strObj.str.includes("Line Spacing")) {
                controls.min = min;
                controls.max = max;
              }
            });
          }

          // Проверка на "Line Spacing" в свойстве capPropUIName
          if (
            controls.capPropUIName &&
            controls.capPropUIName.includes("Line Spacing")
          ) {
            controls.capPropMin = min;
            controls.capPropMax = max;
          }

          // if (controls.type === 14 && controls.uiName && controls.uiName.strDB) {
          //   controls.uiName.strDB.forEach(strObj => {
          //     const placeholder = placeholders.find(p => p.name === strObj.str);
          //     if (placeholder) {
          //       updateDimensions(controls, placeholder);
          //     }
          //   });
          // }
        });

        json.clientControls.push({
          canAnimate: true,
          id: "d4652cb9-c28b-42d0-a351-c39c5774dc79",
          type: 8,
          uiName: {
            strDB: [
              {
                localeString: "en_US",
                str: "(c) AE2",
              },
            ],
          },
          uiSuffix: {
            strDB: [],
          },
          uiToolTip: {
            strDB: [],
          },
          value: {
            strDB: [
              {
                localeString: "en_US",
                str: "",
              },
            ],
          },
        });

        const srcInfo = json.sourceInfoLocalized;
        for (const key in srcInfo) {
          srcInfo[key].capsuleparams.capParams.forEach((params) => {
            params.capPropFontEdit = true;
            params.capPropFontFauxStyleEdit = true;
            params.capPropFontSizeEdit = true;

            // Проверка на "Line Spacing" для sourceInfoLocalized
            if (params.uiName && params.uiName.strDB) {
              params.uiName.strDB.forEach((strObj) => {
                if (strObj.str && strObj.str.includes("Line Spacing")) {
                  params.min = min;
                  params.max = max;
                }
              });
            }

            // Проверка на "Line Spacing" в свойстве capPropUIName для sourceInfoLocalized
            if (
              params.capPropUIName &&
              params.capPropUIName.includes("Line Spacing")
            ) {
              params.capPropMin = min;
              params.capPropMax = max;
            }

            // if (params.capPropType === 11 && params.capPropUIName) {
            //   const placeholder = placeholders.find(p => p.name === params.capPropUIName);
            //   if (placeholder) {
            //     updateDimensions(params, placeholder);
            //   }
            // }
          });

          srcInfo[key].capsuleparams.capParams.push({
            capPropAnimatable: false,
            capPropDefault: "",
            capPropMatchName: "d4652cb9-c28b-42d0-a351-c39c5774dc79",
            capPropType: 4,
            capPropUIName: "(c) AE2",
          });
        }
      }

      async function fixMogrt(filePath, placeholders) {
        // Чтение файла с помощью CEP API
        let fileContent = window.cep.fs.readFile(
          filePath,
          window.cep.encoding.Base64,
        );

        if (fileContent.err !== 0) {
          alert("Error while reading file: " + fileContent.err);
          return;
        }

        // Преобразование Base64 в ArrayBuffer
        let decodedData = base64ToArrayBuffer(fileContent.data);

        const zip = await new JSZip().loadAsync(decodedData);
        const definitionJson = await zip
          .file("definition.json")
          .async("string")
          .then(JSON.parse);

        // Проверка наличия definitionJson
        if (!definitionJson) {
          alert("The definition.json file was not found in the archive!");
          return;
        }

        fixHandler(definitionJson, placeholders);
        zip.file("definition.json", JSON.stringify(definitionJson));

        zip
          .generateAsync({
            type: "base64",
          })
          .then(function (content) {
            var desktopPath = filePath;
            var result = window.cep.fs.writeFile(
              desktopPath,
              content,
              window.cep.encoding.Base64,
            );

            if (result.err === 0) {
              return desktopPath;
              //alert("File updated successfully!");
            } else {
              alert("Error saving file: " + result.err);
              return;
            }
          });
      }

      function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var len = binaryString.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
      }
    },
  );
}

export default fixExportMogrt;
