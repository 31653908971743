import React, { useState, useEffect } from "react";
import "./ExportGraphics.css";
import useVisible from "../../hooks/useVisible.jsx";
import getFonts from "../../utils/buttons/getFonts";
import getFootages from "../../utils/buttons/getFootages";
import handleClick from "../../utils/handleClick.js";

function ExportGraphics({
  onPopupStateChange,
  selectedTag,
  buttonTags,
  generateButtonTags,
}) {
  const [isVisible, setIsVisible] = useVisible("ExportGraphicsVisibility");
  const [helpPdf, setHelpPdf] = useVisible("ExportGraphicsPDF");
  const [fonts, setFonts] = useVisible("ExportGraphicsFonts");
  const [footages, setFootages] = useVisible("ExportGraphicsFootages");
  const [zip, setZip] = useVisible("ExportGraphicsZip");
  const [type, setType] = useState("gt");
  const [format, setFormat] = useState("txt");
  const [mediaType, setMediaType] = useState(() => {
    return localStorage.getItem("ExportGraphicsMediaType") || "none";
  });
  const [textType, setTextType] = useState(() => {
    return localStorage.getItem("ExportGraphicsTextType") || "none";
  });

  const labels = {
    none: {
      color: "666666",
      value: 0,
    },
    red: {
      color: "B53838",
      value: 1,
    },
    yellow: {
      color: "E4D84C",
      value: 2,
    },
    blue: {
      color: "677DE0",
      value: 8,
    },
    green: {
      color: "4AA44C",
      value: 9,
    },
    orange: {
      color: "E8920D",
      value: 11,
    },
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const Dropdown = ({ id, name, labelText, selectedValue, onChange }) => {
    return (
      <div className="render-group__dropdown">
        <span className="graphics__dropdown-text">
          <span
            style={{
              display: "inline-block",
              width: "12px",
              height: "12px",
              backgroundColor: `#${labels[selectedValue]?.color}`,
              marginRight: "8px",
            }}
          ></span>
          {labelText}
        </span>
        <select id={id} name={name} value={selectedValue} onChange={onChange}>
          {Object.keys(labels).map((key) => (
            <option key={key} value={key}>
              {capitalizeFirstLetter(key)}
            </option>
          ))}
        </select>
      </div>
    );
  };

  useEffect(() => {
    localStorage.setItem("ExportGraphicsMediaType", mediaType);
  }, [mediaType]);

  useEffect(() => {
    localStorage.setItem("ExportGraphicsTextType", textType);
  }, [textType]);

  const handleMediaChange = (event) => {
    setMediaType(event.target.value);
  };

  const handleTextChange = (event) => {
    setTextType(event.target.value);
  };

  useEffect(() => {
    localStorage.setItem("ExportGraphicsFormat", format);
  }, [format]); // Эффект отслеживает изменения format

  // Функция для переключения между 'rtf' и 'txt'
  const toggleFormat = () => {
    setFormat((currentFormat) => (currentFormat === "rtf" ? "txt" : "rtf"));
  };

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-download"></i>
        </div>
        <div className="button-card__title">Export Graphics</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          {/* <input
            type="checkbox"
            id="fonts-collect-files"
            checked={fonts}
            onChange={() => {
              setFonts(!fonts);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="fonts-collect-files">
            <span className="custom-checkbox"></span>
            <span className="label-text">Google Fonts</span>
          </label>
          <input
            type="checkbox"
            id="footages-collect-files"
            checked={footages}
            onChange={() => {
              setFootages(!footages);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="footages-collect-files">
            <span className="custom-checkbox"></span>
            <span className="label-text">Links Footages</span>
          </label>
          <div className="collect-files-format" onClick={toggleFormat}>
            {format}
          </div> */}
          <div className="render-group__checkbox-group no-wrap">
            <Dropdown
              id="mediaType"
              name="mediaType"
              labelText="Label Media"
              selectedValue={mediaType}
              onChange={handleMediaChange}
            />
            <Dropdown
              id="textType"
              name="textType"
              labelText="Label Text"
              selectedValue={textType}
              onChange={handleTextChange}
            />
          </div>
        </div>

        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick("exportGraphicTemplates", {
                type: "comp",
                mediaLabel: labels[mediaType].value,
                textLabel: labels[textType].value,
              })
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-image-polaroid"></i>
            <span>Active Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick("exportGraphicTemplates", {
                type: "selected comp",
                mediaLabel: labels[mediaType].value,
                textLabel: labels[textType].value,
              })
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-images"></i>
            <span>Selected Comp</span>
          </div>
          <div
            onClick={() => getFonts(false, true, format, type)}
            className="resolution-tools__button"
          >
            <i class="fa-brands fa-google"></i>
            <span>Google Fonts</span>
          </div>
          <div
            onClick={() => getFootages(format, type)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-image"></i>
            <span>Footage Links</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportGraphics;
