import React, { useContext, useState, useEffect } from "react";
import copyClipboard from "../../utils/copyClipboard";
import useVisible from "../../hooks/useVisible.jsx";
import { useDrag, useDrop } from "react-dnd";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import "./ButtonCard.css";

import ResolutionTools from "../ResolutionTools/ResolutionTools.jsx";
import ColorControls from "../ColorControls/ColorControls.jsx";
import ExportElementsPremiere from "../ExportElementsPremiere/ExportElementsPremiere.jsx";
import MarkerGroup from "../MarkerGroup/MarkerGroup.jsx";
import MotionAnimatorGroup from "../MotionAnimatorGroup/MotionAnimatorGroup.jsx";
import RenderGroup from "../RenderGroup/RenderGroup.jsx";
import MogrtGroup from "../MogrtGroup/MogrtGroup.jsx";
import BakeExpressionGroup from "../BakeExpressionGroup/BakeExpressionGroup.jsx";
import PinsGroup from "../PinsGroup/PinsGroup.jsx";
import TransformPreCompGroup from "../TransformPreCompGroup/TransformPreCompGroup.jsx";
import UnPreCompose from "../UnPreCompose/UnPreCompose.jsx";
import AutoMogrtGroup from "../AutoMogrtGroup/AutoMogrtGroup.jsx";
import TransitionMogrtGroup from "../TransitionMogrtGroup/TransitionMogrtGroup.jsx";
import RenameGroup from "../RenameGroup/RenameGroup.jsx";
import ExportMotionGroup from "../ExportMotionGroup/ExportMotionGroup.jsx";
import SlicedScaleGroup from "../SlicedScaleGroup/SlicedScaleGroup.jsx";
import ShapeGroupAnchor from "../ShapeGroupAnchor/ShapeGroupAnchor.jsx";
import BakeParentGroup from "../BakeParentGroup/BakeParentGroup.jsx";
import NewTemplateGroup from "../NewTemplateGroup/NewTemplateGroup.jsx";
import VideoBolt from "../VideoBolt/VideoBolt.jsx";
import ConvertShape from "../ConvertShape/ConvertShape.jsx";
import ShapeMatteGroup from "../ShapeMatteGroup/ShapeMatteGroup.jsx";
import ExportPremiereGroup from "../ExportPremiereGroup/ExportPremiereGroup.jsx";
import DisableAnimatorGroup from "../DisableAnimatorGroup/DisableAnimatorGroup.jsx";
import DataTemplateGroup from "../DataTemplateGroup/DataTemplateGroup.jsx";
import AutoCropGroup from "../AutoCropGroup/AutoCropGroup.jsx";
import MaskGroup from "../MaskGroup/MaskGroup.jsx";
import ExportDavinciGroup from "../ExportDavinciGroup/ExportDavinciGroup.jsx";
import ExportElementsDaVinci from "../ExportElementsDaVinci/ExportElementsDaVinci.jsx";
import FlipValueGroup from "../FlipValueGroup/FlipValueGroup.jsx";
import AutoCameraGroup from "../AutoCameraGroup/AutoCameraGroup.jsx";
import ReverseKeyMotion from "../ReverseKeyMotion/ReverseKeyMotion.jsx";
import BakePropertyPremiere from "../BakePropertyPremiere/BakePropertyPremiere.jsx";
import ShapeToBezier from "../ShapeToBezier/ShapeToBezier.jsx";
import AutoColorsScheme from "../AutoColorsScheme/AutoColorsScheme.jsx";
import ParallaxAnimator from "../ParallaxAnimator/ParallaxAnimator.jsx";
import ExpressionControls from "../ExpressionControls/ExpressionControls.jsx";
import RenderImportFootage from "../RenderImportFootage/RenderImportFootage.jsx";
import RenderImportFootageMotion from "../RenderImportFootageMotion/RenderImportFootageMotion.jsx";
import Space3D from "../Space3D/Space3D.jsx";
import MacroIcons from "../MacroIcons/MacroIcons.jsx";
import TitlesMogrt from "../TitlesMogrt/TitlesMogrt.jsx";
import ShapeToImage from "../ShapeToImage/ShapeToImage.jsx";
import TransitionsDropZone from "../TransitionsDropZone/TransitionsDropZone.jsx";
import ReviewTools from "../ReviewTools/ReviewTools.jsx";
import Resize from "../Resize/Resize.jsx";
import Grid from "../Grid/Grid.jsx";
import FillPlaceholders from "../FillPlaceholders/FillPlaceholders.jsx";
import SmartPreview from "../SmartPreview/SmartPreview.jsx";
import ColorsSchemes from "../ColorsSchemes/ColorsSchemes.jsx";
import FollowLayer from "../FollowLayer/FollowLayer.jsx";
//import ExportGif from "../ExportGif/ExportGif.jsx";
import CollectFiles from "../CollectFiles/CollectFiles.jsx";
import ExportMogrt from "../ExportMogrt/ExportMogrt.jsx";
import Collapse from "../Collapse/Collapse.jsx";
import NormalizeTrackMatte from "../NormalizeTrackMatte/NormalizeTrackMatte.jsx";
import AutoBeat from "../AutoBeat/AutoBeat.jsx";
import AddControl from "../AddControl/AddControl.jsx";
import ExportGraphics from "../ExportGraphics/ExportGraphics.jsx";
import CreatePlaceholder from "../CreatePlaceholder/CreatePlaceholder.jsx";
import ResponsiveText from "../ResponsiveText/ResponsiveText.jsx";
import MaskToComp from "../MaskToComp/MaskToComp.jsx";
import SplitTextLayer from "../SplitTextLayer/SplitTextLayer.jsx";
import TransitionPreview from "../TransitionPreview/TransitionPreview.jsx";
import SplitTemplate from "../SplitTemplate/SplitTemplate.jsx";
import BakePlaceholder from "../BakePlaceholder/BakePlaceholder.jsx";

import closeComps from "../../utils/buttons/closeComps";
import kanyeWest from "../../utils/buttons/kanyeWest";
//import mogrtFolder from "../../utils/buttons/mogrtFolder";

const DND_ITEM_TYPE = "BUTTON";

const ButtonCard = ({
  button,
  moveButton,
  findButton,
  selectedTag,
  tags,
  bakeKeyDr,
}) => {
  const currentUser = useContext(CurrentUserContext);
  const { category, name, script, argument, icon } = button;
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const [buttonTags, setButtonTags] = useState(() => {
    // При инициализации проверяем, есть ли сохраненные теги в localStorage
    const savedTags = localStorage.getItem(`button-tags-${button._id}`);
    return savedTags ? JSON.parse(savedTags) : [];
  });

  function generateButtonTags() {
    return (
      <div className="button-card__tags">
        {tags.map((tag) => (
          <div
            key={tag.id}
            className={`button-tag tags-container__tag ${buttonTags.includes(tag.id) ? "active" : ""}`}
            onClick={(event) => toggleButtonTag(tag.id, event)}
            style={{ backgroundColor: tag.color }}
            title={tag.name}
          ></div>
        ))}
      </div>
    );
  }

  const toggleButtonTag = (id, event) => {
    event.stopPropagation();

    if (buttonTags.includes(id)) {
      // Убираем тег из массива
      setButtonTags(buttonTags.filter((tagId) => tagId !== id));
    } else {
      // Добавляем тег в массив
      setButtonTags([...buttonTags, id]);
    }
  };

  useEffect(() => {
    // Сохраняем выбранные теги в localStorage при изменении состояния
    localStorage.setItem(
      `button-tags-${button._id}`,
      JSON.stringify(buttonTags),
    );
  }, [buttonTags]); // Следим за изменениями в buttonTags

  const handlePopupStateChange = (state) => {
    setPopupIsVisible(state);
  };
  const isDragDisabled = popupIsVisible;

  const originalIndex = findButton(button._id).index;

  const [{ isDragging }, drag] = useDrag({
    type: DND_ITEM_TYPE,
    item: { _id: button._id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { _id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveButton(droppedId, originalIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover({ _id: draggedId }) {
      if (draggedId !== button._id) {
        const { index: overIndex } = findButton(button._id);
        moveButton(draggedId, overIndex);
      }
    },
  });

  const opacity = isDragging ? 0 : 1;

  function handleClick() {
    if (script !== "") {
      window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
      window.jsx.evalScript(
        `run(${script}(${JSON.stringify(argument)}));`,
        function (result) {
          if (script === "controlsDR" && result) {
            copyClipboard(result);
          }
        },
      );
    }

    if (name.toLowerCase().match("close compositions")) {
      closeComps();
    }

    if (name.toLowerCase().match("kanye west")) {
      kanyeWest();
    }

    // if (
    //   name.toLowerCase().match("collect files") ||
    //   name.toLowerCase().match("google fonts")
    // ) {
    //   name.toLowerCase().match("collect files")
    //     ? getFonts(true)
    //     : getFonts(false);
    // }
  }

  if (name.toLowerCase() === "import premiere pro") {
    if (currentUser.role === "admin" || currentUser.role === "user") {
    } else {
      return null;
    }
  }

  if (name.toLowerCase() === "collect files") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <CollectFiles
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "bake placeholder") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <BakePlaceholder
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "create placeholder") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <CreatePlaceholder
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
          category={category}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "add control") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <AddControl
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
          category={category}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "collapse") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <Collapse
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "resolution tools") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ResolutionTools
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "auto beat") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <AutoBeat
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "auto camera group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <AutoCameraGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "export graphics") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ExportGraphics
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
    // getFonts(false, true, 'rtf', 'gt');
    // getFootages('rtf', 'gt');
  }

  if (name.toLowerCase() === "transition preview") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <TransitionPreview
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
    // getFonts(false, true, 'rtf', 'gt');
    // getFootages('rtf', 'gt');
  }

  if (name.toLowerCase() === "color controls") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ColorControls
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "export elements") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ExportElementsPremiere
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "markers") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <MarkerGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "motion animator") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <MotionAnimatorGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "render layers") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <RenderGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "bake expression") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <BakeExpressionGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "3d pins") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <PinsGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "transform pre-compose") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <TransformPreCompGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "rename group") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <RenameGroup
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "export motion group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ExportMotionGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "auto mogrt group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <AutoMogrtGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "transition mogrt") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <TransitionMogrtGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "sliced scale group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <SlicedScaleGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "shape group anchor") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ShapeGroupAnchor
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "new template group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <NewTemplateGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "videobolt group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <VideoBolt
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
          closeComps={closeComps}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "shape matte group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ShapeMatteGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "export premiere group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ExportPremiereGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "convert shape group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ConvertShape
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "bake parent group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <BakeParentGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "disable animator group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <DisableAnimatorGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "data template group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <DataTemplateGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "auto crop group") {
    console.log(buttonTags);
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <AutoCropGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "mask convert group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <MaskGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "flip value group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <FlipValueGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "reverse key") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ReverseKeyMotion
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "bake property") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <BakePropertyPremiere
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "shape to bezier path") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ShapeToBezier
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "auto colors scheme") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <AutoColorsScheme
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "parallax animator") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ParallaxAnimator
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "expression controls") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ExpressionControls
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "render & import footage") {
    if (category === "pp") {
      return (
        <li
          className="button-group"
          ref={(node) => drag(drop(node))}
          style={{ opacity }}
        >
          <RenderImportFootage
            selectedTag={selectedTag}
            buttonTags={buttonTags}
            generateButtonTags={generateButtonTags}
          />
        </li>
      );
    }

    if (category === "am") {
      return (
        <li
          className="button-group"
          ref={(node) => drag(drop(node))}
          style={{ opacity }}
        >
          <RenderImportFootageMotion
            selectedTag={selectedTag}
            buttonTags={buttonTags}
            generateButtonTags={generateButtonTags}
          />
        </li>
      );
    }
  }

  if (name.toLowerCase() === "3d space") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <Space3D
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "macro icons") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <MacroIcons
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "export davinci group") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ExportDavinciGroup
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "export elements davinci") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ExportElementsDaVinci
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "titles mogrt") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <TitlesMogrt
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "un-precompose text") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <UnPreCompose
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "grid") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <Grid
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "shape to image") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ShapeToImage
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "transitions drop zone") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <TransitionsDropZone
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "review tools") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <ReviewTools
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "fill placeholders") {
    return (
      <li
        className="button-group"
        ref={(node) => drag(drop(node))}
        style={{ opacity }}
      >
        <FillPlaceholders
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "smart preview") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <SmartPreview
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "colors schemes") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <ColorsSchemes
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "follow layer") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <FollowLayer
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "responsive text") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <ResponsiveText
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "mask to comp") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <MaskToComp
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "split text") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <SplitTextLayer
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "split template") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <SplitTemplate
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "export gif" && currentUser.role === "admin") {
    return null;
    // <li
    //   className="button-group"
    //   ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
    //   style={{ opacity: isDragDisabled ? 1 : opacity }}
    // >
    //   <ExportGif
    //     onPopupStateChange={(popupState) =>
    //       handlePopupStateChange(popupState)
    //     }
    //     selectedTag={selectedTag}
    //     buttonTags={buttonTags}
    //     generateButtonTags={generateButtonTags}
    //   />
    // </li>
  }

  // if (name.toLowerCase() === "resize +") {
  //   return (
  //     <li
  //       className="button-group"
  //       ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
  //       style={{ opacity: isDragDisabled ? 1 : opacity }}
  //     >
  //       <Resize
  //         onPopupStateChange={(popupState) =>
  //           handlePopupStateChange(popupState)
  //         }
  //         selectedTag={selectedTag}
  //         buttonTags={buttonTags}
  //         generateButtonTags={generateButtonTags}
  //       />
  //     </li>
  //   );
  // }

  if (name.toLowerCase() === "export mogrt") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <ExportMogrt
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (name.toLowerCase() === "normalize track matte") {
    return (
      <li
        className="button-group"
        ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
        style={{ opacity: isDragDisabled ? 1 : opacity }}
      >
        <NormalizeTrackMatte
          onPopupStateChange={(popupState) =>
            handlePopupStateChange(popupState)
          }
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags}
        />
      </li>
    );
  }

  if (
    currentUser.role === "admin" ||
    currentUser.role === "user" ||
    currentUser.role === "test"
  ) {
    if (name.toLowerCase() === "group mogrt") {
      return (
        <li
          className="button-group"
          ref={(node) => (isDragDisabled ? node : drag(drop(node)))}
          style={{ opacity: isDragDisabled ? 1 : opacity }}
        >
          <MogrtGroup
            onPopupStateChange={(popupState) =>
              handlePopupStateChange(popupState)
            }
            selectedTag={selectedTag}
            buttonTags={buttonTags}
            generateButtonTags={generateButtonTags}
          />
        </li>
      );
    }
  }

  const isButtonDisabled =
    currentUser.subscription && currentUser.subscription[category] === false;

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <li
      className={`button-card ${isButtonDisabled ? "button-card--disabled" : ""}`}
      onClick={isButtonDisabled ? null : handleClick}
      ref={(node) => drag(drop(node))}
      style={isButtonDisabled ? {} : { opacity }}
    >
      <div className="button-card__icon">
        <div dangerouslySetInnerHTML={{ __html: icon }} />
      </div>
      <div className="button-card__title">{name}</div>
      {generateButtonTags()}
    </li>
  );
};

export default ButtonCard;
