import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function DataTemplateGroup({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("DataTemplateGroupVisibility");
  const [capsDataTemplate, setDataCapsTemplate] =
    useVisible("capsDataTemplate");
  const [preComp, setDataPrecomp] = useVisible("dataPreComp");
  const [duration, setDuration] = useVisible("dataDuration");
  const [fpsValue, setFpsValue] = useVisible("fpsDataValue");

  useEffect(() => {
    localStorage.setItem("duration", duration);
  }, [duration]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-file-import"></i>
        </div>
        <div className="button-card__title">Import Ai - Ps Template</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Duration</span>
            <input
              type="number"
              id="duration"
              name="duration"
              className="rename__input"
              onChange={(event) => setDuration(event.target.value)}
              value={duration}
            />
          </div>

          {/* Второй выпадающий список */}
          <div className="render-group__dropdown">
            <span className="render-group__dropdown-text">Framerate</span>
            <select
              id="fps"
              name="fps"
              //className="render-group__dropdown"
              onChange={(event) => setFpsValue(event.target.value)}
              value={fpsValue}
            >
              <option value="30">30</option>
              <option value="60">60</option>
            </select>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="preComp"
              checked={preComp}
              onChange={() => {
                setDataPrecomp(!preComp);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="preComp">
              <span className="custom-checkbox"></span>
              <span className="label-text">Auto Pre-Comp</span>
            </label>
          </div>

          {/* Ваш чекбокс */}
          {/* <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="capsDataTemplate"
              checked={capsDataTemplate}
              onChange={() => setDataCapsTemplate(!capsDataTemplate)}
              className="hidden-checkbox"
            />

            <label htmlFor="capsDataTemplate">
              <span className="custom-checkbox"></span>
              <span className="label-text">Caps Name</span>
            </label>
          </div> */}
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick("aiImportData", {
                fps: Number(fpsValue) || 30,
                duration: Number(duration) || 6,
                preComp: preComp || false,
                capsName: false,
              })
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-square-i"></i>
            <span className="render-group__button-text">Illustrator</span>
          </div>
          <div
            onClick={() =>
              handleClick("psImportData", {
                fps: Number(fpsValue) || 30,
                duration: Number(duration) || 6,
                preComp: preComp || false,
                capsName: false,
              })
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-square-p"></i>
            <span className="render-group__button-text">Photoshop</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataTemplateGroup;
