import mainApi from "../../utils/api.js";
import useVisible from "../../hooks/useVisible.jsx";
import React, { useState, useEffect, useContext, useRef } from "react";
import getFonts from "../../utils/buttons/getFonts";
import getFootages from "../../utils/buttons/getFootages";
import copyClipboard from "../../utils/copyClipboard";
import CurrentUserContext from "../../contexts/CurrentUserContext.jsx";
import { USER_FOLDER } from "../../utils/constants";

function ExportDavinciGroup({ selectedTag, buttonTags, generateButtonTags }) {
  const currentUser = useContext(CurrentUserContext);
  const [isVisible, setIsVisible] = useVisible("ExportDavinciGroupVisibility");
  const [bakeKeyDr, setBakeKeyDr] = useVisible("bakeKeyDr");
  const [reverseControls, setReverseControls] = useVisible("reverseControlsDR");
  const [textResponsive, setTextResponsive] = useVisible("textResponsiveMacro");
  // const [controlsGroup, setControlsGroup] = useVisible("controlsGroupMacro");
  const [expressionDr, setExpressionDr] = useVisible("expressionDavinci");
  // const [save, setSave] = useVisible("saveDavinci");

  const [type, setType] = useState("drm");
  const [format, setFormat] = useState("txt");

  const [typeMacro, setTypeMacro] = useState(() => {
    return localStorage.getItem("typeMacro") || "titles";
  });

  const [macroData, setMacroData] = useState("");
  const isFirstRender = useRef(true);

  const fetchAndWriteFile = async (filename, path) => {
    try {
      const response = await fetch(`./files/${filename}`);
      if (response.ok) {
        // Используем Blob для бинарных данных
        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          const base64DataOnly = base64data.split(",")[1]; // Удалить часть data:url
          const writeResult = window.cep.fs.writeFile(
            `${path}/${filename}`,
            base64DataOnly,
            window.cep.encoding.Base64,
          );
          if (writeResult.err === 0) {
            //alert("File written successfully:", filename);
          } else {
            alert("Error writing file:", filename);
          }
        };
      } else {
        alert("Failed to fetch file:", filename);
      }
    } catch (error) {
      //alert("An error occurred:", error);
    }
  };

  const handleClick = (script, argument) => {
    // const path = `${USER_FOLDER}/Harchenko/AE2DR/`;
    // await fetchAndWriteFile("fusion.drp", path);
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if (script === "exportDrMacro") {
        if (result) {
          const path = `${USER_FOLDER}/Harchenko/AE2DR/dr-template.json`;
          const saveResult = window.cep.fs.writeFile(
            path,
            result,
            window.cep.encoding.UTF8,
          );

          if (saveResult.err === 0) {
            console.log("Файл успешно сохранен:", path);
          } else {
            alert("Error saving file:", saveResult.err);
          }

          // setMacroData(result);

          const data = JSON.parse(result);
          if (data.templateContent.length > 1) {
            const processedStrings = data.templateContent.map((item) => {
              return item.content
                .replace(/^\{\s*Tools\s*=\s*ordered\(\)\s*\{/, "")
                .replace(/\}\}$/, "");
            });

            // Объединяем все строки в одну
            const combinedContent = processedStrings.join("\n");

            // Копируем объединенный контент в буфер обмена
            copyClipboard(`{Tools = ordered() {${combinedContent}}}`);
          } else {
            copyClipboard(data.templateContent[0].content);
          }

          window.jsx.evalScript(
            "run($.ae.message(" + JSON.stringify("Export Successful") + "));",
            function (result) {},
          );
        }
      }
    });
  };

  const handleChange = (event) => {
    setTypeMacro(event.target.value);
    console.log(event.target.value);
  };

  useEffect(() => {
    localStorage.setItem("typeMacro", typeMacro);
  }, [typeMacro]);

  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false; // После первого рендера сбрасываем флаг
  //     return; // Выходим, чтобы не выполнять эффект
  //   }

  //   mainApi
  //     .updateUser(currentUser._id, {
  //       drTemplate: macroData,
  //     })
  //     .then((res) => {
  //       console.log("Update user drTemplate");
  //       window.jsx.evalScript(
  //         "run($.ae.message(" + JSON.stringify("Export Successful") + "));",
  //         function (result) {},
  //       );
  //     })
  //     .catch((error) => {
  //       console.error(
  //         "Failed to update tags for user:",
  //         currentUser._id,
  //         error,
  //       );
  //       window.jsx.evalScript(
  //         "run($.ae.message(" + JSON.stringify(error) + "));",
  //         function (result) {},
  //       );
  //     });
  // }, [macroData]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-sharp fa-regular fa-square-d"></i>
        </div>
        <div className="button-card__title">Export Macro</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__dropdown">
            <select value={typeMacro} onChange={handleChange}>
              <option value="titles">Title</option>
              <option value="transitions">Transition</option>
              <option value="generators">Generator</option>
              <option value="effects">Effect</option>
            </select>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="expressionDr"
              checked={expressionDr}
              onChange={() => setExpressionDr(!expressionDr)}
              className="hidden-checkbox"
            />
            <label htmlFor="expressionDr">
              <span className="custom-checkbox"></span>
              <span className="label-text">Controls</span>
            </label>
          </div>
          {expressionDr && (
            <>
              {/* <div className="render-group__checkbox">
      <input
        type="checkbox"
        id="controlsGroupMacro"
        checked={controlsGroup}
        onChange={() => setControlsGroup(!controlsGroup)}
        className="hidden-checkbox"
      />
      <label htmlFor="controlsGroupMacro">
        <span className="custom-checkbox"></span>
        <span className="label-text">Controls Group</span>
      </label>
    </div> */}
              <div className="render-group__checkbox">
                <input
                  type="checkbox"
                  id="reverseControlsDr"
                  checked={reverseControls}
                  onChange={() => setReverseControls(!reverseControls)}
                  className="hidden-checkbox"
                />
                <label htmlFor="reverseControlsDr">
                  <span className="custom-checkbox"></span>
                  <span className="label-text">Reverse Controls</span>
                </label>
              </div>
            </>
          )}
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="textResponsiveMacro"
              checked={textResponsive}
              onChange={() => setTextResponsive(!textResponsive)}
              className="hidden-checkbox"
            />
            <label htmlFor="textResponsiveMacro">
              <span className="custom-checkbox"></span>
              <span className="label-text">Responsive Text</span>
            </label>
          </div>
          {/* <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="saveDR"
            checked={save}
            onChange={() => setSave(!save)}
            className="hidden-checkbox"
          />
          <label htmlFor="saveDR">
            <span className="custom-checkbox"></span>
            <span className="label-text">Auto Save</span>
          </label>
        </div> */}
        </div>
        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(
                "exportDrMacro",
                JSON.stringify({
                  bakeKey: true,
                  colorControl: expressionDr,
                  reverseControls: reverseControls,
                  textResponsive: textResponsive,
                  // controlsGroup: controlsGroup,
                  typeMacro: typeMacro,
                  notification: false,
                  type: "comp",
                  templateMacro: false,
                  // save: save
                }),
              )
            }
            className="render-group__button"
          >
            <i class="fa-light fa-square-arrow-up-right"></i>
            <span className="render-group__button-text">Active Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "exportDrMacro",
                JSON.stringify({
                  bakeKey: true,
                  colorControl: expressionDr,
                  reverseControls: reverseControls,
                  textResponsive: textResponsive,
                  // controlsGroup: controlsGroup,
                  typeMacro: typeMacro,
                  notification: false,
                  type: "selected comp",
                  templateMacro: false,
                  // save: save
                }),
              )
            }
            className="render-group__button"
          >
            <i class="fa-solid fa-images"></i>
            <span className="render-group__button-text">Selected Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "exportDrMacro",
                JSON.stringify({
                  bakeKey: true,
                  colorControl: expressionDr,
                  reverseControls: reverseControls,
                  textResponsive: textResponsive,
                  // controlsGroup: controlsGroup,
                  typeMacro: typeMacro,
                  notification: false,
                  type: "selected comp",
                  templateMacro: true,
                  // save: save
                }),
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-folder-image"></i>
            <span className="render-group__button-text">Macro Template</span>
          </div>
          <div
            onClick={() => handleClick("createHelpMA", JSON.stringify(type))}
            className="render-group__button"
          >
            <i class="fa-sharp fa-light fa-file-pdf"></i>
            <span className="render-group__button-text">Help PDF</span>
          </div>
          <div
            onClick={() => getFonts(false, true, format, type)}
            className="render-group__button"
          >
            <i class="fa-brands fa-google"></i>
            <span className="render-group__button-text">Google Fonts</span>
          </div>
          <div
            onClick={() => getFootages(format, type)}
            className="render-group__button"
          >
            <i class="fa-regular fa-image"></i>
            <span className="render-group__button-text">Footage Links</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportDavinciGroup;
