import useVisible from "../../hooks/useVisible.jsx";
import "./VideoBolt.css";

function VideoBolt({
  selectedTag,
  buttonTags,
  generateButtonTags,
  closeComps,
}) {
  const [isVisible, setIsVisible] = useVisible("VideoBoltVisibility");
  const [vbPointText, vbSetPointText] = useVisible("vbPointText");
  const [vbOpenComp, vbSetOpenComp] = useVisible("vbOpenComp");
  const [transparent, setTransparent] = useVisible("vbTransparent");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-square-v"></i>
          <div />
        </div>
        <div className="button-card__title">Videobolt Tools</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="vbPointText"
              checked={vbPointText}
              onChange={() => vbSetPointText(!vbPointText)}
              className="hidden-checkbox"
            />
            <label htmlFor="vbPointText">
              <span className="custom-checkbox"></span>
              <span className="label-text">Convert Point Text</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="vbOpenComp"
              checked={vbOpenComp}
              onChange={() => vbSetOpenComp(!vbOpenComp)}
              className="hidden-checkbox"
            />
            <label htmlFor="vbOpenComp">
              <span className="custom-checkbox"></span>
              <span className="label-text">Open Comp</span>
            </label>
          </div>
        </div>

        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick(
                "vbGlobalFitAndAlign",
                JSON.stringify({
                  pointText: vbPointText,
                  openComp: vbOpenComp,
                }),
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-regular fa-text-height"></i>
            <span>Fit and Align</span>
          </div>
          <div
            onClick={() => handleClick("capsDisable", false)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-text-size"></i>
            <span>Caps Disable</span>
          </div>

          <div
            onClick={() => handleClick("vbCleanText", false)}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-solid fa-text-slash"></i>
            <span>Text Clean</span>
          </div>
          <div
            onClick={() => handleClick("vbSortPlaceholders", false)}
            className="resolution-tools__button"
          >
            <i class="fa-light fa-folder-tree"></i>
            <span>Sort Placeholders</span>
          </div>
          <div
            onClick={() => handleClick("vbPlaceholdersRename", false)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-image"></i>
            <span>Rename Placeholders</span>
          </div>
          <div
            onClick={() => handleClick("vbEffectsRename", false)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-square-e"></i>
            <span>Rename Effects</span>
          </div>
          <div
            onClick={() => handleClick("vbCopyEffect", false)}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-solid fa-copy"></i>
            <span>Copy Effect</span>
          </div>
        </div>

        <div className="render-group__checkbox-group mg-top">
          <input
            type="checkbox"
            id="vbTransparent"
            checked={transparent}
            onChange={() => setTransparent(!transparent)}
            className="hidden-checkbox"
          />
          <label htmlFor="vbTransparent">
            <span className="custom-checkbox"></span>
            <span className="label-text">Transparent</span>
          </label>
        </div>
        <div className="videobolt-tools">
          <div
            onClick={() => handleClick("vbMetadata", false)}
            className="resolution-tools__button"
          >
            <i class="fa-sharp-duotone fa-regular fa-arrow-down-to-line"></i>
            <span>Metadata</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoBolt;
