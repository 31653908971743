import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import getFonts from "../../utils/buttons/getFonts";
import fixExportMogrt from "../../utils/buttons/fixExportMogrt";
import getFootages from "../../utils/buttons/getFootages";
import zipTemplate from "../../utils/zipTemplate.js";

function ExportMogrt({
  onPopupStateChange,
  selectedTag,
  buttonTags,
  generateButtonTags,
}) {
  const [isVisible, setIsVisible] = useVisible("ExportMogrtVisibility");
  const [helpPdf, setHelpPdf] = useVisible("ExportMogrtPDF");
  const [fonts, setFonts] = useVisible("ExportMogrtFonts");
  const [footages, setFootages] = useVisible("ExportMogrtFootages");
  const [zip, setZip] = useVisible("ExportMogrtZip");
  const [type, setType] = useState("mg");
  const [format, setFormat] = useState("txt");

  useEffect(() => {
    localStorage.setItem("ExportMogrtFormat", format);
  }, [format]); // Эффект отслеживает изменения format

  // Функция для переключения между 'rtf' и 'txt'
  const toggleFormat = () => {
    setFormat((currentFormat) => (currentFormat === "rtf" ? "txt" : "rtf"));
  };

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  // Убедитесь, что zipTemplate правильно обрабатывает и возвращает Promise

  const handleClick = async (pdf, fonts, footages, zip, format) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    getFonts(false, fonts, format, type);
    if (footages) {
      getFootages(format, type);
    }

    try {
      const result = await fixExportMogrt(pdf);
    } catch (error) {
      //alert(error); // Обработка ошибки
    }

    if (zip) {
      // Используйте Promise для ожидания завершения zipTemplate
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          window.jsx.evalScript(
            `run(getTemplateFilesZip(${JSON.stringify({
              type: type,
              help: pdf,
              fonts: fonts,
              footages: footages,
            })}));`,
            function (data) {
              if (data) {
                zipTemplate(JSON.parse(data))
                  .then(resolve) // Разрешаем Promise после завершения zipTemplate
                  .catch(reject); // Отклоняем Promise в случае ошибки
              } else {
                resolve(); // Разрешаем Promise, если данных нет
              }
            },
          );
        }, 3000);
      });
    }
  };

  const handleHelp = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-download"></i>
        </div>
        <div className="button-card__title">Export Mogrt</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        {/* <div className="render-group__checkbox-group">
          <input
            type="checkbox"
            id="helpPdf-export-mogrt"
            checked={helpPdf}
            onChange={() => {
              setHelpPdf(!helpPdf);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="helpPdf-export-mogrt">
            <span className="custom-checkbox"></span>
            <span className="label-text">Help PDF</span>
          </label>
          <input
            type="checkbox"
            id="fonts-export-mogrt"
            checked={fonts}
            onChange={() => {
              setFonts(!fonts);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="fonts-export-mogrt">
            <span className="custom-checkbox"></span>
            <span className="label-text">Google Fonts</span>
          </label>
          <input
            type="checkbox"
            id="footages-export-mogrt"
            checked={footages}
            onChange={() => {
              setFootages(!footages);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="footages-export-mogrt">
            <span className="custom-checkbox"></span>
            <span className="label-text">Links Footages</span>
          </label>
          <input
            type="checkbox"
            id="zip-export-mogrt"
            checked={zip}
            onChange={() => {
              setZip(!zip);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="zip-export-mogrt">
            <span className="custom-checkbox"></span>
            <span className="label-text">Create Zip</span>
          </label>
          <div className="collect-files-format" onClick={toggleFormat}>
            {format}
          </div>
        </div> */}

        <div className="videobolt-tools">
          <div
            onClick={() => handleClick(false, false, false, false, format)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-download"></i>
            <span>Export</span>
          </div>
          <div
            onClick={() => handleHelp("createHelpMA", JSON.stringify(type))}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-light fa-file-pdf"></i>
            <span>Help PDF</span>
          </div>
          <div
            onClick={() => getFonts(false, true, format, type, "google")}
            className="resolution-tools__button"
          >
            <i class="fa-brands fa-google"></i>
            <span>Google Fonts</span>
          </div>
          <div
            onClick={() => getFonts(false, true, format, type, "adobe")}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-font"></i>
            <span>Adobe Fonts</span>
          </div>
          <div
            onClick={() => getFootages(format, type)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-image"></i>
            <span>Footage Links</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportMogrt;
