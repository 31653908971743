import React, { useEffect, useState } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import "./AnchorAlignNull.css";
import handleClick from "../../utils/handleClick.js";

function AnchorAlignNull({ script }) {
  const iconsConfig = [
    {
      arg: "top-left",
      icon: {
        "$.ae.moveAnchor": (
          <i
            class="fa-regular fa-arrow-up-to-arc fa-rotate-by"
            style={{ "--fa-rotate-angle": "-45deg" }}
          ></i>
        ),
        alignTo: <i class="fa-light fa-arrow-up-left"></i>,
        newNull: (
          <i
            className="fa-solid fa-chevron-up fa-rotate-by"
            style={{ "--fa-rotate-angle": "-45deg" }}
          ></i>
        ),
      },
    },
    {
      arg: "top",
      icon: {
        "$.ae.moveAnchor": <i class="fa-regular fa-arrow-up-to-arc"></i>,
        alignTo: <i class="fa-light fa-arrow-up"></i>,
        newNull: <i class="fa-solid fa-chevron-up"></i>,
      },
    },
    {
      arg: "top-right",
      icon: {
        "$.ae.moveAnchor": (
          <i
            class="fa-regular fa-arrow-up-to-arc fa-rotate-by"
            style={{ "--fa-rotate-angle": "45deg" }}
          ></i>
        ),
        alignTo: <i class="fa-light fa-arrow-up-right"></i>,
        newNull: (
          <i
            className="fa-solid fa-chevron-up fa-rotate-by"
            style={{ "--fa-rotate-angle": "45deg" }}
          ></i>
        ),
      },
    },
    {
      arg: "left",
      icon: {
        "$.ae.moveAnchor": <i class="fa-regular fa-arrow-left-to-arc"></i>,
        alignTo: <i class="fa-light fa-arrow-left"></i>,
        newNull: <i class="fa-solid fa-chevron-left"></i>,
      },
    },
    {
      arg: "center",
      icon: {
        "$.ae.moveAnchor": <i class="fa-sharp fa-regular fa-plus"></i>,
        alignTo: <i class="fa-sharp fa-regular fa-plus"></i>,
        newNull: <i class="fa-sharp fa-regular fa-plus"></i>,
      },
    },
    {
      arg: "right",
      icon: {
        "$.ae.moveAnchor": <i class="fa-regular fa-arrow-right-to-arc"></i>,
        alignTo: <i class="fa-light fa-arrow-right"></i>,
        newNull: <i class="fa-solid fa-chevron-right"></i>,
      },
    },
    {
      arg: "bottom-left",
      icon: {
        "$.ae.moveAnchor": (
          <i
            class="fa-regular fa-arrow-down-to-arc fa-rotate-by"
            style={{ "--fa-rotate-angle": "45deg" }}
          ></i>
        ),
        alignTo: <i class="fa-light fa-arrow-up-left fa-flip-vertical"></i>,
        newNull: (
          <i
            class="fa-solid fa-chevron-down fa-rotate-by"
            style={{ "--fa-rotate-angle": "45deg" }}
          ></i>
        ),
      },
    },
    {
      arg: "bottom",
      icon: {
        "$.ae.moveAnchor": <i class="fa-regular fa-arrow-down-to-arc"></i>,
        alignTo: <i class="fa-light fa-arrow-up fa-flip-vertical"></i>,
        newNull: <i class="fa-solid fa-chevron-down"></i>,
      },
    },
    {
      arg: "bottom-right",
      icon: {
        "$.ae.moveAnchor": (
          <i
            class="fa-regular fa-arrow-down-to-arc fa-rotate-by"
            style={{ "--fa-rotate-angle": "-45deg" }}
          ></i>
        ),
        alignTo: <i class="fa-light fa-arrow-up-right fa-flip-vertical"></i>,
        newNull: (
          <i
            class="fa-solid fa-chevron-down fa-rotate-by"
            style={{ "--fa-rotate-angle": "-45deg" }}
          ></i>
        ),
      },
    },
  ];

  return (
    <div className="anchorAlignContainer">
      <div className="anchorAlignContainer__iconsGrid">
        {iconsConfig.map((obj, index) => (
          <div
            key={index}
            className="anchorAlignContainer__icon"
            onClick={() => handleClick(script, obj.arg)}
          >
            {obj.icon[script]}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AnchorAlignNull;
