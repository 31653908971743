import handleClick from "../../utils/handleClick.js";
import useVisible from "../../hooks/useVisible.jsx";

function BakePlaceholder({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("BakePlaceholderVisibility");

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-image"></i>
          <div />
        </div>
        <div className="button-card__title">Bake Placeholder</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() => handleClick("createPlaceholder", false)}
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-image"></i>
          <span>Placeholder</span>
        </div>
        <div
          onClick={() => handleClick("importVideoPlaceholder", false)}
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-film"></i>
          <span>Import Video Placeholder</span>
        </div>
      </div>
    </>
  );
}

export default BakePlaceholder;
