import React, { useState, useRef, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";
import "./MogrtGroup.css";
const JSZip = require("jszip");

const DND_ITEM_TYPE = "ITEM";
const DND_GROUP_TYPE = "GROUP";

const data = [
  {
    name: "Text 01 Color",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,1,1,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Text 01 Color")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Text 02 Color",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,1,1,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Text 02 Color")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Big Line Color 1",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,1,1,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Big Line Color 1")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Big Line Color 2",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,0.63529413938522,0,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Big Line Color 2")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Big Line Color 3",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,1,1,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Big Line Color 3")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Top Line Color 1",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,0.63529413938522,0,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Top Line Color 1")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Top Line Color 2",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,1,1,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Top Line Color 2")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Top Line Color 3",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,0.63529413938522,0,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Top Line Color 3")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Bottom Line Color 1",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,0.63529413938522,0,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Bottom Line Color 1")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Bottom Line Color 2",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,1,1,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Bottom Line Color 2")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Bottom Line Color 3",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,0.63529413938522,0,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Bottom Line Color 3")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Rectangle Left Color",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,0.63529413938522,0,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Rectangle Left Color")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Rectangle Right Color",
    propertyType: { type: 4, capPropType: 3 },
    value: "1,0.63529413938522,0,1",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Rectangle Right Color")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Text 01 Size",
    propertyType: { type: 2, capPropType: 1 },
    value: "100",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Text 01 Size")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Text 01 Position",
    propertyType: { type: 5, capPropType: 6 },
    value: "0,0",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Text 01 Position")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Text 02 Size",
    propertyType: { type: 2, capPropType: 1 },
    value: "100",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Text 02 Size")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Text 02 Position",
    propertyType: { type: 5, capPropType: 6 },
    value: "0,0",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Text 02 Position")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Box Size",
    propertyType: { type: 2, capPropType: 1 },
    value: "100",
    script: 'app.project.item(6).layer(1)("ADBE Layer Overrides")("Box Size")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Box Position",
    propertyType: { type: 5, capPropType: 6 },
    value: "0,0",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Box Position")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Global Size",
    propertyType: { type: 2, capPropType: 1 },
    value: "100",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Global Size")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Global Position",
    propertyType: { type: 5, capPropType: 6 },
    value: "0,0",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Global Position")',
    path: false,
    layerType: "controls",
    comp: "Title 01",
    parentFolder: "Title 01",
    offline: false,
  },
  {
    name: "Text 02 - Text",
    propertyType: { type: 6, capPropType: 0 },
    value: "MODERN AND SMOOTH ANIMATION",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Text 02 - Text")',
    path: false,
    layerType: "text",
    comp: "Text 02",
    parentFolder: "Text",
    offline: false,
  },
  {
    name: "Text 01 - Text",
    propertyType: { type: 6, capPropType: 0 },
    value: "CREATIVE",
    script:
      'app.project.item(6).layer(1)("ADBE Layer Overrides")("Text 01 - Text")',
    path: false,
    layerType: "text",
    comp: "Text 01",
    parentFolder: "Text",
    offline: false,
  },
];
function IconBasedOnType(type) {
  switch (type) {
    case 5:
      return <i class="fa-light fa-arrows-up-down-left-right"></i>;
    case 2:
      return <i class="fa-regular fa-slider"></i>;
    case 9:
      return <i class="fa-light fa-arrows-maximize"></i>;
    case 4:
      return <i class="fa-solid fa-eye-dropper"></i>;
    case 1:
      return <i class="fa-sharp fa-regular fa-square-check"></i>;
    case 13:
      return <i class="fa-light fa-list-dropdown"></i>;
    case 6:
      return <i class="fa-regular fa-text-size"></i>;
    case 14:
      return <i class="fa-sharp fa-regular fa-image"></i>;
    case 3:
      return <i class="fa-regular fa-arrows-rotate"></i>;
    default:
      return null;
  }
}

function convertToRGBA(value) {
  const values = value.split(",").map((num) => parseFloat(num) * 255);
  return `rgba(${values[0]}, ${values[1]}, ${values[2]}, ${values[3]})`;
}

function DraggableItem({
  item,
  id,
  moveItem,
  isSelected,
  onSelect,
  isInGroup,
  updateItemProperty,
}) {
  const ref = useRef(null);
  const [isInputFocused, setInputFocused] = useState(false);

  const [, drop] = useDrop({
    accept: [DND_ITEM_TYPE, DND_GROUP_TYPE],
    hover(draggedItem) {
      if (draggedItem.id !== item.id) {
        moveItem(draggedItem.id, item.id, "before");
        onSelect(draggedItem.id, false);
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DND_ITEM_TYPE,
    item: { id: item.id },
    canDrag: !isInputFocused,
    collect: (monitor) => ({
      isDragging: monitor.isDragging() && monitor.getItem().id === item.id,
    }),
  });

  function handleProperty(script) {
    if (process.env.NODE_ENV !== "development") {
      window.jsx.evalScript(script.toString(), function (filePath) {});
    }
  }

  drag(drop(ref));

  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        onSelect(id, e.shiftKey, e.ctrlKey || e.metaKey);
      }}
      className={`mogrt-group__item ${isInGroup ? "item-in-group" : ""}`}
      style={{
        backgroundColor: isSelected ? "#161616" : "",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <input
        className={`item__input ${item.isNameError ? "input-error" : ""}`}
        value={item.newName !== undefined ? item.newName : item.name}
        onChange={(e) => {
          const newName = e.target.value;
          updateItemProperty(id, "name", newName);
        }}
        onClick={(e) => {
          e.stopPropagation();
          onSelect(id, e.shiftKey, e.ctrlKey || e.metaKey);
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
      />
      {item.propertyType.type === 2 && (
        <div className="mogrt__group-sliders">
          <div className="mogrt-group__item-slider">
            <input
              className="item__input-slider"
              type="number"
              maxLength="4"
              value={item.min}
              onChange={(e) => updateItemProperty(id, "min", e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
            <input
              className="item__input-slider"
              type="number"
              maxLength="4"
              value={item.max}
              onChange={(e) => updateItemProperty(id, "max", e.target.value)}
              onMouseDown={(e) => e.stopPropagation()}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
          </div>
          <input
            className="item__range-slider"
            type="range"
            min={item.min}
            max={item.max}
            value={item.value}
            onChange={(e) => {
              // console.log("Slider value:", e.target.value);
              onSelect(id, e.shiftKey, e.ctrlKey || e.metaKey);
              updateItemProperty(id, "value", e.target.value);
              handleProperty(`${item.script}.setValue(${e.target.value})`);
            }}
            onMouseDown={(e) => e.stopPropagation()}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
        </div>
      )}

      {item.propertyType.type === 4 && (
        <div
          className="item__color"
          style={{ backgroundColor: convertToRGBA(item.value) }}
        ></div>
      )}
      {/*
      {item.propertyType.type === 14 && (
        <img src={item.path} alt="Placeholder" />
      )} */}

      <div className="mogrt-group__item-icon">
        {IconBasedOnType(item.propertyType.type)}
      </div>
    </div>
  );
}

function DraggableGroup({
  item,
  id,
  moveItem,
  onSelect,
  selectedIndices,
  toggleGroup,
  deleteGroup,
  updateItemProperty,
}) {
  const ref = useRef(null);
  const [isInputFocused, setInputFocused] = useState(false);
  const [canDragItem, setCanDragItem] = useState(true);

  const [, drop] = useDrop({
    accept: [DND_ITEM_TYPE, DND_GROUP_TYPE],
    hover(draggedItem, monitor) {
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();

      if (draggedItem.id !== item.id) {
        // Если перетаскиваемый элемент — это не группа и текущая группа раскрыта:
        if (draggedItem.type === DND_ITEM_TYPE && item.expanded) {
          moveItem(draggedItem.id, item.id, "inside");
        } else {
          // Если элемент находится выше группы:
          if (clientOffset.y < hoverBoundingRect.top) {
            moveItem(draggedItem.id, item.id, "before");
          }
          // Если элемент находится ниже группы:
          else if (clientOffset.y > hoverBoundingRect.bottom) {
            moveItem(draggedItem.id, item.id, "after");
          }
        }
        onSelect(draggedItem.id, false);
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DND_GROUP_TYPE,
    canDrag: canDragItem && !isInputFocused && !item.expanded,
    item: { id: item.id }, // снова предполагая, что у вас есть свойство id внутри item
    collect: (monitor) => ({
      isDragging: monitor.isDragging() && monitor.getItem().id === item.id,
    }),
  });

  const handleToggleGroupClick = (groupId) => {
    setCanDragItem(false);
    toggleGroup(groupId); // используем id вместо index
    setTimeout(() => {
      setCanDragItem(true);
    }, 10);
  };

  drag(drop(ref));

  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        onSelect(id, e.shiftKey, e.ctrlKey || e.metaKey); // используем id вместо index
      }}
      className={`group ${
        selectedIndices.includes(id) && !item.expanded ? "selected" : ""
      }`}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className="group__input_item">
        <div
          className={`group__input_toggle ${item.expanded ? "open" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            handleToggleGroupClick(id); // используем id вместо index
          }}
        >
          <i class="fa-solid fa-chevron-right"></i>
        </div>

        <input
          className={`group__input ${item.isNameError ? "input-error" : ""}`}
          value={item.name}
          onChange={(e) => {
            const newName = e.target.value;
            updateItemProperty(id, "name", newName);
          }}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
        />
        <div
          className="group__input-delete"
          onClick={(e) => {
            deleteGroup(id); // используем id
          }}
        >
          <i class="fa-regular fa-trash"></i>
        </div>
      </div>

      <div className={`${item.expanded ? "group__items" : ""}`}>
        {item.type === "group" &&
          item.expanded &&
          item.content && // проверка на наличие content
          item.content.map((childItem) => {
            if (!childItem) return null;
            if (childItem.type === "item") {
              return (
                <DraggableItem
                  key={childItem.id}
                  item={childItem}
                  id={childItem.id} // Используйте ID вместо индекса
                  moveItem={moveItem}
                  isSelected={selectedIndices.includes(childItem.id)} // Проверьте наличие ID, а не индекса
                  onSelect={onSelect}
                  isInGroup={true}
                  updateItemProperty={updateItemProperty}
                />
              );
            } else if (childItem.type === "group") {
              return (
                <DraggableGroup
                  key={childItem.id}
                  item={childItem}
                  id={childItem.id} // Используйте ID вместо индекса
                  moveItem={moveItem}
                  onSelect={onSelect}
                  selectedIndices={selectedIndices}
                  toggleGroup={toggleGroup}
                  deleteGroup={deleteGroup}
                  updateItemProperty={updateItemProperty}
                />
              );
            }
            return null;
          })}
      </div>
    </div>
  );
}

function MogrtGroup(props) {
  const popupRef = useRef(null);
  const [items, setItems] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [dataFilePath, setDataFilePath] = useState(false);

  const checkUniqueName = (structure, name, excludeId) => {
    for (const element of structure) {
      if (element.name === name && element.id !== excludeId) {
        return false;
      }

      if (
        element.type === "item" &&
        element.newName === name &&
        element.id !== excludeId
      ) {
        return false;
      }

      if (element.type === "group" && element.content) {
        if (!checkUniqueName(element.content, name, excludeId)) {
          return false;
        }
      }
    }
    return true;
  };

  const updateItemProperty = (id, key, value) => {
    setItems((prevItems) => {
      const updateItems = (items) => {
        return items.map((item) => {
          if (item.id === id) {
            const updatedItem = {
              ...item,
              [key]: key === "name" && item.type === "item" ? undefined : value,
              newName:
                key === "name" && item.type === "item" ? value : item.newName,
            };
            return updatedItem;
          }

          if (item.type === "group" && item.content) {
            return {
              ...item,
              content: updateItems(item.content),
            };
          }

          return item;
        });
      };

      const newItems = updateItems(prevItems);

      const setItemErrorFlags = (items) => {
        return items.map((item) => {
          let isNameError = false;

          if (item.type === "group" && item.content) {
            const updatedContent = setItemErrorFlags(item.content);
            isNameError =
              updatedContent.some((child) => child.isNameError) ||
              !checkUniqueName(newItems, item.name, item.id);

            return {
              ...item,
              isNameError,
              content: updatedContent,
            };
          }

          if (item.type === "item") {
            const itemName = item.newName || item.name;
            isNameError = !checkUniqueName(newItems, itemName, item.id);
          }

          return {
            ...item,
            isNameError,
          };
        });
      };

      return setItemErrorFlags(newItems);
    });
  };

  function MogrtData({ onReceiveData }) {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");

    window.jsx.evalScript("run(returnMogrtFile());", function (filePath) {
      if (filePath) {
        const jsonFilePath = filePath.replace(/\.\w+$/, ".json");
        let fileContent = window.cep.fs.readFile(
          jsonFilePath,
          window.cep.encoding.UTF8,
        );

        if (fileContent.err !== 0) {
          window.jsx.evalScript("run(getMogrtData());", function (data) {
            if (data && onReceiveData) {
              onReceiveData(data);
            }
          });
        } else {
          setDataFilePath(jsonFilePath);
        }
        //prompt('', fileContent.data)
        setItems(JSON.parse(fileContent.data));
      }
    });
  }

  function deleteDataFile(path) {
    const result = window.cep.fs.deleteFile(path);

    if (result.err === 0) {
      setDataFilePath(false);
      MogrtData({
        onReceiveData: (data) => {
          const parsedData = JSON.parse(data);
          const initialData = createInitialData(parsedData);
          setItems(initialData);
        },
      });
    } else {
      alert("Failed to delete file");
    }
  }

  function autoSort(items) {
    const groupsToCreate = [];
    const scenes = {};
    const sceneMap = {};
    const globalIds = [];
    const controlsIds = [];
    const mediaItems = [];
    const textItems = [];
    const usedControlIds = new Set(); // Для отслеживания использованных контроллеров
    let count = 0;
    let sceneCount = 0;

    // Первый проход: определение элементов для группировки
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type === "group") continue;

      if (item.name.includes("Global")) {
        globalIds.push(item.id);
        continue;
      }

      if (item.layerType === "controls") {
        controlsIds.push(item.id);
        continue;
      }

      if (item.parentFolder && item.parentFolder.includes("Scene")) {
        if (!scenes[item.parentFolder]) {
          sceneCount++;
          scenes[item.parentFolder] = true;
        }
      }

      if (
        sceneCount !== 1 &&
        item.parentFolder &&
        item.parentFolder.includes("Scene")
      ) {
        if (!sceneMap[item.parentFolder]) {
          count++;
          sceneMap[item.parentFolder] = {
            media: [],
            text: {},
            index: count,
          };
        }

        if (item.layerType === "media") {
          sceneMap[item.parentFolder].media.push(item);
        } else if (item.layerType === "text") {
          if (!sceneMap[item.parentFolder].text[item.comp]) {
            sceneMap[item.parentFolder].text[item.comp] = [];
          }
          sceneMap[item.parentFolder].text[item.comp].push(item);
        }
      } else {
        if (item.layerType === "media") {
          mediaItems.push(item);
        } else if (item.layerType === "text") {
          textItems.push(item);
        }
      }
    }

    // Добавление глобальных элементов в группы
    if (globalIds.length > 0) {
      const globalGroup = {
        id: uuidv4(),
        type: "group",
        propertyType: { type: 10, capPropType: 8 },
        name: "Global Control",
        content: globalIds.map((id) => items.find((item) => item.id === id)),
        expanded: false,
        isNameError: false,
      };
      groupsToCreate.push(globalGroup);
    }

    // Группировка текстовых элементов
    if (textItems.length > 0) {
      const textGroupMap = {};

      for (const textItem of textItems) {
        const compName = textItem.comp || "Unknown Comp";
        if (!textGroupMap[compName]) {
          textGroupMap[compName] = [];
        }
        textGroupMap[compName].push(textItem);
      }

      const textGroupContent = Object.keys(textGroupMap).map((compName) => {
        const groupContent = textGroupMap[compName];

        // Если в группе только один элемент, добавляем соответствующие контроллеры
        if (groupContent.length === 1) {
          const matchingControls = controlsIds
            .map((id) => items.find((item) => item.id === id))
            .filter((control) => control.name.includes(compName)); // Поиск совпадений имени

          // Добавляем контроллеры в текстовую группу
          groupContent.push(...matchingControls);

          // Записываем их ID как использованные
          matchingControls.forEach((control) => usedControlIds.add(control.id));
        }

        return {
          id: uuidv4(),
          type: "group",
          propertyType: { type: 10, capPropType: 8 },
          name: compName,
          content: groupContent,
          expanded: false,
          isNameError: false,
        };
      });

      // Сортировка и добавление текстовых групп
      textGroupContent.sort((a, b) => a.name.localeCompare(b.name));

      if (textItems.length >= 2) {
        const textGroup = {
          id: uuidv4(),
          type: "group",
          propertyType: { type: 10, capPropType: 8 },
          name: "Text Controllers",
          content: textGroupContent,
          expanded: false,
          isNameError: false,
        };

        groupsToCreate.push(textGroup);
      } else {
        groupsToCreate.push(...textGroupContent);
      }
    }

    // Фильтрация оставшихся контроллеров
    const remainingControls = controlsIds.filter(
      (id) => !usedControlIds.has(id),
    );

    // Сортировка и группировка медиа элементов
    if (mediaItems.length > 0) {
      const sortedMediaItems = mediaItems.sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      const mediaGroup = {
        id: uuidv4(),
        type: "group",
        propertyType: { type: 10, capPropType: 8 },
        name: "Media Placeholders",
        content: sortedMediaItems,
        expanded: false,
        isNameError: false,
      };
      groupsToCreate.push(mediaGroup);
    }

    // Сортировка сцен и создание групп для них
    const sortedSceneNames = Object.keys(sceneMap).sort();

    for (const scene of sortedSceneNames) {
      const sceneGroup = {
        id: uuidv4(),
        type: "group",
        propertyType: { type: 10, capPropType: 8 },
        name: scene,
        content: [],
        expanded: false,
        isNameError: false,
      };

      // Сортировка медиа элементов
      const sortedMediaItems = sceneMap[scene].media.sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      if (sortedMediaItems.length > 0) {
        const mediaGroup = {
          id: uuidv4(),
          type: "group",
          propertyType: { type: 10, capPropType: 8 },
          name: "Media Placeholders" + " ".repeat(sceneMap[scene].index),
          content: sortedMediaItems,
          expanded: false,
          isNameError: false,
        };
        sceneGroup.content.push(mediaGroup);
      }

      // Добавление текстовых групп
      const textControllersGroup = {
        id: uuidv4(),
        type: "group",
        propertyType: { type: 10, capPropType: 8 },
        name: "Text Controllers" + " ".repeat(sceneMap[scene].index),
        content: [],
        expanded: false,
        isNameError: false,
      };

      for (const comp in sceneMap[scene].text) {
        const textGroup = {
          id: uuidv4(),
          type: "group",
          propertyType: { type: 10, capPropType: 8 },
          name: comp,
          content: sceneMap[scene].text[comp],
          expanded: false,
          isNameError: false,
        };
        textControllersGroup.content.push(textGroup);
      }

      if (textControllersGroup.content.length > 0) {
        sceneGroup.content.push(textControllersGroup);
      }

      groupsToCreate.push(sceneGroup);
    }

    // Теперь добавляем группу "Controls" как вторую, если она существует
    if (remainingControls.length > 0) {
      const controlsGroup = {
        id: uuidv4(),
        type: "group",
        propertyType: { type: 10, capPropType: 8 },
        name: "Controls",
        content: remainingControls.map((id) =>
          items.find((item) => item.id === id),
        ),
        expanded: false,
        isNameError: false,
      };
      groupsToCreate.splice(1, 0, controlsGroup);
    }

    return groupsToCreate;
  }

  function autoSortAndGroup() {
    const groupsToCreate = autoSort(items);
    const newItems = [];
    let processedIds = new Set();

    // Обработка групп и подгрупп
    for (const group of groupsToCreate) {
      if (Array.isArray(group.content)) {
        for (const subGroup of group.content) {
          if (Array.isArray(subGroup.content)) {
            for (const subSubGroup of subGroup.content) {
              if (Array.isArray(subSubGroup.content)) {
                for (const item of subSubGroup.content) {
                  processedIds.add(item.id);
                }
              } else {
                processedIds.add(subSubGroup.id);
              }
            }
          } else {
            processedIds.add(subGroup.id);
          }
        }
      }
      newItems.push(group);
    }

    // Добавление оставшихся элементов, которые не были обработаны
    for (const item of items) {
      if (!processedIds.has(item.id)) {
        newItems.push(item);
        processedIds.add(item.id);
      }
    }

    setItems(newItems);
    setSelectedIndices([]);
  }

  const toggleGroup = (id) => {
    const updateGroup = (items) => {
      return items.map((item) => {
        if (!item) return null;
        if (item.id === id && item.type === "group") {
          return { ...item, expanded: !item.expanded };
        } else if (
          item.type === "group" &&
          item.content &&
          item.content.length > 0
        ) {
          // Если у нас есть вложенная группа, обновляем ее контент рекурсивно
          return { ...item, content: updateGroup(item.content) };
        }
        return item;
      });
    };

    setItems((prevItems) => updateGroup(prevItems));
  };

  const collapseGroups = (expand) => {
    const updateGroup = (items) => {
      return items.map((item) => {
        if (!item) return null;
        if (item.type === "group") {
          return {
            ...item,
            expanded: expand, // Устанавливаем состояние всех групп на основе аргумента expand
            content:
              item.content && item.content.length > 0
                ? updateGroup(item.content)
                : item.content,
          };
        }
        return item;
      });
    };

    setItems((prevItems) => updateGroup(prevItems));
  };

  function linearizeItems(items) {
    let result = [];
    items.forEach((item) => {
      result.push(item);
      if (!item) return null;
      if (item.type === "group" && item.expanded) {
        // Проверяем, что группа раскрыта
        result = result.concat(linearizeItems(item.content));
      }
    });
    return result;
  }

  const handleSelect = (itemId, isShiftPressed, isCtrlOrCmdPressed) => {
    const linearItems = linearizeItems(items);
    const currentIndex = linearItems.findIndex(
      (item) => item && item.id === itemId,
    );

    if (currentIndex === -1) return;

    // Обработка Shift
    if (isShiftPressed && selectedIndices.length > 0) {
      const startIndex = linearItems.findIndex(
        (item) => item.id === selectedIndices[0],
      );
      if (startIndex === -1) return;

      const endIndex = currentIndex;

      const range =
        startIndex <= endIndex
          ? [...Array(endIndex - startIndex + 1).keys()].map(
              (i) => startIndex + i,
            )
          : [...Array(startIndex - endIndex + 1).keys()].map(
              (i) => endIndex + i,
            );

      setSelectedIndices(range.map((i) => linearItems[i].id));
    }
    // Обработка Ctrl или Cmd
    else if (isCtrlOrCmdPressed) {
      if (selectedIndices.includes(itemId)) {
        setSelectedIndices(selectedIndices.filter((id) => id !== itemId));
      } else {
        setSelectedIndices([...selectedIndices, itemId]);
      }
    }
    // Обычный клик
    else {
      setSelectedIndices([itemId]);
    }
  };

  function getAllGroups(items) {
    let groups = [];

    items.forEach((item) => {
      if (item.type === "group") {
        groups.push(item);
        groups = groups.concat(getAllGroups(item.content));
      }
    });

    return groups;
  }

  function recursiveGroupItems(items, selectedIds) {
    const grouped = selectedIds
      .map((id) => items.find((item) => item.id === id))
      .filter(Boolean);

    if (grouped.length === selectedIds.length) {
      // Используйте функцию getAllGroups для получения всех групп
      const groupCount = getAllGroups(items).length;
      const groupName = `Group ${groupCount + 1}`;

      const newGroup = {
        id: uuidv4(),
        type: "group",
        propertyType: { type: 10, capPropType: 8 },
        name: groupName,
        content: grouped,
        expanded: false,
      };

      const indexOfFirstSelected = Math.min(
        ...selectedIds.map((id) => items.findIndex((item) => item.id === id)),
      );

      const newItems = [...items];
      newItems.splice(indexOfFirstSelected, 0, newGroup);

      const updatedItems = newItems.filter(
        (item) => !selectedIds.includes(item.id),
      );

      return updatedItems;
    } else {
      return items.map((item) => {
        if (item.type === "group") {
          return {
            ...item,
            content: recursiveGroupItems(item.content, selectedIds),
          };
        }
        return item;
      });
    }
  }

  const groupItems = () => {
    if (selectedIndices.length < 1) {
      alert("Please select more than one item to create a group.");
      return;
    }

    const updatedItems = recursiveGroupItems(items, selectedIndices);
    setItems(updatedItems);
    setSelectedIndices([]);
  };

  const deleteGroup = (groupId) => {
    const updatedItems = [...items];

    // Находим группу и её родительский контейнер
    const found = findItemAndParent(updatedItems, groupId);
    if (!found) return; // Если группа не найдена, просто возвращаемся

    const { parent, index } = found;

    // Получаем элементы из удаляемой группы
    const groupItems = parent[index].content;

    // Удаляем группу из родительского контейнера
    parent.splice(index, 1);

    // Вставляем элементы из группы на место удаляемой группы
    parent.splice(index, 0, ...groupItems);

    setItems(updatedItems);
  };

  function findItemAndParent(items, id, parent = null) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.id === id) {
        return { item, index: i, parent: parent || items };
      }
      if (item.type === "group") {
        const result = findItemAndParent(item.content, id, item.content);
        if (result) return result;
      }
    }

    return null;
  }

  const moveItem = (fromId, toId, mode = "before") => {
    const updatedItems = [...items];

    const from = findItemAndParent(updatedItems, fromId);
    const to = findItemAndParent(updatedItems, toId);

    if (!from || !to) return;

    const [movedItem] = from.parent.splice(from.index, 1);

    if (mode === "before") {
      to.parent.splice(to.index, 0, movedItem);
    } else if (mode === "after") {
      to.parent.splice(to.index + 1, 0, movedItem);
    } else if (mode === "inside" && to.item.type === "group") {
      // Добавьте дополнительную проверку здесь. Если группа закрыта или ее контент пуст,
      // то вставьте перед группой, а не внутри нее.
      if (to.item.expanded && to.item.content && to.item.content.length > 0) {
        to.item.content.push(movedItem);
      } else {
        to.parent.splice(to.index, 0, movedItem);
      }
    }

    setItems(updatedItems);
  };

  function processItem(item, index, result) {
    const nameToUse = item.newName || item.name;
    if (!item) return null;
    if (item.type === "group") {
      result.push({
        id: item.id,
        type: "group",
        name: item.name,
        items: item.content.map((groupItem) => ({
          name: groupItem.newName || groupItem.name,
          type: groupItem.type === "group" ? "group" : "item",
          propertyType: groupItem.propertyType,
        })),
        expanded: item.expanded,
      });

      item.content.forEach((groupItem, groupIndex) => {
        processItem(groupItem, groupIndex, result);
      });
    } else if (item.propertyType.type === 2) {
      result.push({
        type: "item",
        name: item.name,
        newName: nameToUse,
        min: item.min,
        max: item.max,
        value: item.value,
        propertyType: item.propertyType,
      });
    } else {
      result.push({
        type: "item",
        name: item.name,
        newName: nameToUse,
        value: item.value,
        propertyType: item.propertyType,
      });
    }
  }

  function getFormattedData() {
    const result = [];
    items.forEach((item, index) => {
      processItem(item, index, result); // Передаем index
    });
    //console.log(items);
    console.log(result);
    //prompt("", JSON.stringify(result));
    return result;
  }

  function createGroupControl(group, isClientControl) {
    return isClientControl
      ? {
          canAnimate: true,
          groupexpanded: group.expanded,
          id: group.id,
          type: 10,
          uiName: {
            strDB: [
              {
                localeString: "en_US",
                str: group.name,
              },
            ],
          },
          uiSuffix: {
            strDB: [],
          },
          uiToolTip: {
            strDB: [],
          },
          value: [],
        }
      : {
          capPropAnimatable: false,
          capPropDefault: [],
          capPropGroupExpanded: group.expanded,
          capPropMatchName: group.id,
          capPropType: 8,
          capPropUIName: group.name,
        };
  }

  function addControlsToGroup(group, inputControls, isClientControl, data) {
    const groupControl = createGroupControl(group, isClientControl);
    let orderedControls = [groupControl];

    for (const item of group.items) {
      let itemName = item.newName ? item.newName : item.name;

      const isItemGroup = item.type === "group";

      // Обработка для групп
      if (isItemGroup) {
        const nestedGroup = data.find(
          (d) => d.name === itemName && d.type === "group",
        );
        if (nestedGroup) {
          const nestedGroupControls = addControlsToGroup(
            nestedGroup,
            inputControls,
            isClientControl,
            data,
          );

          if (isClientControl) {
            groupControl.value.push(nestedGroupControls[0].id);
          } else {
            groupControl.capPropDefault.push(
              nestedGroupControls[0].capPropMatchName,
            );
            orderedControls.push(...nestedGroupControls);
          }
          continue;
        }
      }

      // Обработка для элементов управления
      let itemControl = isClientControl
        ? inputControls.find(
            (control) =>
              control.uiName &&
              control.uiName.strDB[0].str === itemName &&
              control.type !== 10,
          )
        : inputControls.find(
            (control) =>
              control.capPropUIName === itemName && control.capPropType !== 8,
          );

      if (itemControl) {
        if (isClientControl) {
          groupControl.value.push(itemControl.id);
        } else {
          groupControl.capPropDefault.push(itemControl.capPropMatchName);
          orderedControls.push(itemControl);
        }
      }
    }

    return orderedControls;
  }

  function fixHandler(data, definitionJson) {
    const inputJSON = definitionJson;
    let outputJSON = { ...inputJSON };

    data.forEach((item) => {
      if (item.type === "item") {
        const control = outputJSON.clientControls.find(
          (control) =>
            control.uiName.strDB[0].str === item.name &&
            control.type === item.propertyType.type,
        );
        if (control) {
          control.uiName.strDB[0].str = item.newName;

          // Обновляем min и max в clientControls, если они присутствуют в item
          if (item.hasOwnProperty("min") && item.type !== 10) {
            control.min = Number(item.min);
          }
          if (item.hasOwnProperty("max") && item.type !== 10) {
            control.max = Number(item.max);
          }
        }

        const param =
          outputJSON.sourceInfoLocalized.en_US.capsuleparams.capParams.find(
            (param) =>
              param.capPropUIName === item.name &&
              param.capPropType === item.propertyType.capPropType,
          );
        if (param) {
          param.capPropUIName = item.newName;

          // Обновляем capPropMin и capPropMax в capsuleparams.capParams, если они присутствуют в item
          if (item.hasOwnProperty("min") && item.type !== 8) {
            param.capPropMin = Number(item.min);
          }
          if (item.hasOwnProperty("max") && item.type !== 8) {
            param.capPropMax = Number(item.max);
          }
        }
      }
    });

    const itemsInGroups = new Set();
    data.forEach((element) => {
      if (element.type === "group") {
        element.items.forEach((item) =>
          itemsInGroups.add(item.newName || item.name),
        );
      }
    });

    outputJSON.clientControls = [];
    const tempCapParams = [];

    for (const element of data) {
      if (
        element.type === "item" &&
        !itemsInGroups.has(element.newName || element.name)
      ) {
        let name = element.newName || element.name;
        const itemControlInCapsule =
          inputJSON.sourceInfoLocalized.en_US.capsuleparams.capParams.find(
            (control) =>
              control.capPropUIName === name &&
              control.capPropType === element.propertyType.capPropType,
          );
        if (
          itemControlInCapsule &&
          !tempCapParams.some(
            (control) =>
              control.capPropUIName === itemControlInCapsule.capPropUIName &&
              control.capPropType === itemControlInCapsule.capPropType,
          )
        ) {
          tempCapParams.push(itemControlInCapsule);
        }

        const itemControlInClient = inputJSON.clientControls.find(
          (control) =>
            control.uiName &&
            control.uiName.strDB[0].str === name &&
            control.type === element.propertyType.type,
        );
        if (
          itemControlInClient &&
          !outputJSON.clientControls.some(
            (control) =>
              control.uiName.strDB[0].str ===
                itemControlInClient.uiName.strDB[0].str &&
              control.type === itemControlInClient.type,
          )
        ) {
          outputJSON.clientControls.push(itemControlInClient);
        }
      } else if (element.type === "group") {
        const groupControlsInCapsule = addControlsToGroup(
          element,
          inputJSON.sourceInfoLocalized.en_US.capsuleparams.capParams,
          false,
          data,
        );
        for (const control of groupControlsInCapsule) {
          if (
            !tempCapParams.some(
              (c) => c.capPropUIName === control.capPropUIName,
            )
          ) {
            tempCapParams.push(control);
          }
        }

        const clientControls = addControlsToGroup(
          element,
          inputJSON.clientControls,
          true,
          data,
        );
        for (const control of clientControls) {
          if (
            !outputJSON.clientControls.some(
              (c) => c.uiName.strDB[0].str === control.uiName.strDB[0].str,
            )
          ) {
            outputJSON.clientControls.push(control);
          }
        }
      }
    }

    for (const control of inputJSON.clientControls) {
      if (!outputJSON.clientControls.some((oc) => oc.id === control.id)) {
        outputJSON.clientControls.push(control);
      }
    }

    outputJSON.sourceInfoLocalized.en_US.capsuleparams.capParams =
      tempCapParams;

    outputJSON.clientControls.forEach((controls) => {
      if (controls.fonteditinfo) {
        const info = controls.fonteditinfo;
        info.capPropFontEdit = true;
        info.capPropFontFauxStyleEdit = true;
        info.capPropFontSizeEdit = true;
      }
    });

    outputJSON.clientControls.push({
      canAnimate: true,
      id: "d4652cb9-c28b-42d0-a351-c39c5774dc79",
      type: 8,
      uiName: {
        strDB: [
          {
            localeString: "en_US",
            str: "(c) AE2",
          },
        ],
      },
      uiSuffix: {
        strDB: [],
      },
      uiToolTip: {
        strDB: [],
      },
      value: {
        strDB: [
          {
            localeString: "en_US",
            str: "",
          },
        ],
      },
    });

    const srcInfo = outputJSON.sourceInfoLocalized;
    for (const key in srcInfo) {
      srcInfo[key].capsuleparams.capParams.forEach((params) => {
        params.capPropFontEdit = true;
        params.capPropFontFauxStyleEdit = true;
        params.capPropFontSizeEdit = true;
      });

      srcInfo[key].capsuleparams.capParams.push({
        capPropAnimatable: false,
        capPropDefault: "",
        capPropMatchName: "d4652cb9-c28b-42d0-a351-c39c5774dc79",
        capPropType: 4,
        capPropUIName: "(c) AE2",
      });
    }

    return outputJSON;
  }

  async function readFileAsync(filePath, encoding) {
    return new Promise((resolve, reject) => {
      const fileContent = window.cep.fs.readFile(filePath, encoding);
      if (fileContent.err !== 0) {
        reject(new Error(`Error reading file: ${fileContent.err}`));
      } else {
        resolve(fileContent.data);
      }
    });
  }

  async function writeFileAsync(filePath, data, encoding) {
    return new Promise((resolve, reject) => {
      const result = window.cep.fs.writeFile(filePath, data, encoding);
      if (result.err !== 0) {
        reject(new Error(`Error writing file: ${result.err}`));
      } else {
        resolve();
      }
    });
  }

  async function deleteFileAsync(filePath) {
    return new Promise((resolve, reject) => {
      const result = window.cep.fs.deleteFile(filePath);
      if (result.err !== 0) {
        reject(new Error(`Error deleting file: ${result.err}`));
      } else {
        resolve();
      }
    });
  }

  async function fixMogrt(structure, filePath) {
    try {
      // Чтение файла
      const fileContentBase64 = await readFileAsync(
        filePath,
        window.cep.encoding.Base64,
      );
      const decodedData = base64ToArrayBuffer(fileContentBase64);

      // Работа с архивом
      const zip = await JSZip.loadAsync(decodedData);
      const definitionJson = JSON.parse(
        await zip.file("definition.json").async("string"),
      );

      if (!definitionJson) {
        alert("The definition.json file was not found in the archive!");
        return;
      }

      const newJSON = fixHandler(structure, definitionJson);
      zip.file("definition.json", JSON.stringify(newJSON));

      // Проверка наличия ae2data.json
      const directoryPath = filePath.replace(/[^/\\]*$/, ""); // Удаляем имя файла, оставляем путь к директории
      const ae2dataFilePath = directoryPath + "ae2data.json";

      try {
        const ae2dataContent = await readFileAsync(
          ae2dataFilePath,
          window.cep.encoding.UTF8,
        );
        zip.file("ae2data.json", ae2dataContent);
      } catch (error) {
        console.warn("ae2data.json file not found or can't be read.");
      }

      // Генерация архива
      const updatedZipContent = await zip.generateAsync({ type: "base64" });

      // Сохранение файла
      await writeFileAsync(
        filePath,
        updatedZipContent,
        window.cep.encoding.Base64,
      );

      // Удаление ae2data.json (если существовал)
      try {
        await deleteFileAsync(ae2dataFilePath);
      } catch (error) {
        console.warn(`Unable to delete ae2data.json: ${error.message}`);
      }

      // Уведомление о завершении
      window.jsx.evalScript(
        `run($.ae.message(${JSON.stringify("File updated successfully!")}));`,
        function (result) {},
      );
    } catch (error) {
      console.error("An error occurred:", error.message);
      alert(`An error occurred: ${error.message}`);
    }
  }

  async function updateMogrt() {
    try {
      const duplicate = findDuplicateNames(items);
      if (duplicate.length !== 0) {
        alert(`Some item names are not unique!\n${duplicate.join(", ")}`);
        return;
      }

      // Подключение файла JSX
      window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");

      // Вызов скрипта экспортирования
      const data = await new Promise((resolve, reject) => {
        window.jsx.evalScript(
          `run(exportMogrt(${JSON.stringify({ message: false, help: false })}));`,
          (result) => {
            if (result) {
              resolve(result);
            } else {
              reject(new Error("Data is missing or invalid."));
            }
          },
        );
      });

      // Проверка и разбор данных
      const parsedData = JSON.parse(data);
      if (
        !parsedData.files ||
        !Array.isArray(parsedData.files) ||
        parsedData.files.length === 0
      ) {
        throw new Error(
          "Invalid data format: 'files' array is missing or empty.",
        );
      }

      const filePath = parsedData.files[0];
      if (typeof filePath !== "string" || filePath.trim() === "") {
        throw new Error("Invalid file path in 'files' array.");
      }

      // Формирование пути к JSON-файлу
      const jsonFilePath = filePath.replace(/[^/\\]*$/, "ae2data.json");
      if (!jsonFilePath) {
        throw new Error("Failed to construct path for JSON file.");
      }

      setDataFilePath(jsonFilePath);

      const jsonData = JSON.stringify(items);
      if (!jsonData) {
        throw new Error("Error serializing data to JSON.");
      }

      // Запись JSON данных в файл
      await writeFileAsync(jsonFilePath, jsonData, window.cep.encoding.UTF8);

      // Форматированные данные
      const formattedData = getFormattedData();
      if (!formattedData) {
        throw new Error("Formatted data is missing or invalid.");
      }

      // Вызов функции fixMogrt
      await fixMogrt(formattedData, filePath);

      // alert("Operation completed successfully!");
    } catch (error) {
      console.error("An error occurred in updateMogrt:", error.message);
      alert(`An error occurred: ${error.message}`);
    }

    function findDuplicateNames(structure) {
      const names = [];
      const duplicates = [];

      function checkForDuplicates(items) {
        for (const item of items) {
          const name = item.newName || item.name;

          if (names.includes(name)) {
            if (!duplicates.includes(name)) {
              duplicates.push(name);
            }
          } else {
            names.push(name);
          }

          if (item.type === "group" && item.content) {
            checkForDuplicates(item.content);
          }
        }
      }

      checkForDuplicates(structure);
      return duplicates;
    }
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const isNameUnique = (currentName, list) => {
    return list.filter((item) => item.name === currentName).length === 1;
  };

  const createInitialData = (data) => {
    const initialData = [];

    data.forEach((item) => {
      if (!item.offline) {
        // console.log(item.name);
        // console.log(item.propertyType.capPropType);
        let obj = {
          id: uuidv4(),
          type: "item",
          layerType: item.layerType,
          comp: item.comp,
          parentFolder: item.parentFolder,
          name: item.name,
          propertyType: item.propertyType,
          value: item.value,
          script: item.script,
          path: item.path,
          isNameError: !isNameUnique(item.name, data),
        };

        if (item.propertyType.type === 2) {
          obj.min = 0;
          obj.max = 100;
        }

        if (
          item.propertyType.type === 2 &&
          item.name.toLowerCase().includes("line spacing")
        ) {
          obj.min = -300;
          obj.max = 300;
        }

        initialData.push(obj);
      }
    });

    return initialData;
  };

  useEffect(() => {
    if (isPopupVisible) {
      if (process.env.NODE_ENV === "development") {
        const initialData = createInitialData(data);
        setItems(initialData);
      } else {
        MogrtData({
          onReceiveData: (data) => {
            const parsedData = JSON.parse(data);
            const initialData = createInitialData(parsedData);
            setItems(initialData);
          },
        });
      }
    }
  }, [isPopupVisible]);

  useEffect(() => {
    if (props.onPopupStateChange) {
      props.onPopupStateChange(isPopupVisible);
    }
  }, [isPopupVisible]);

  useEffect(() => {
    const checkSelectedItemsInsideGroup = () => {
      if (selectedIndices.length === 0) {
        setIsDisabled(true);
        return;
      }

      // Найдем родительскую группу для первого выбранного элемента.
      const firstSelected = findItemAndParent(items, selectedIndices[0]);
      if (!firstSelected) {
        setIsDisabled(true);
        return;
      }

      const parentOfFirstSelected = firstSelected.parent;

      // Проверим, что все остальные выбранные элементы имеют ту же родительскую группу.
      for (const id of selectedIndices.slice(1)) {
        const result = findItemAndParent(items, id);
        if (!result || result.parent !== parentOfFirstSelected) {
          setIsDisabled(true);
          return;
        }
      }

      setIsDisabled(false);
    };

    checkSelectedItemsInsideGroup();
  }, [selectedIndices, items]); // добавлено `items` в зависимости

  if (props.selectedTag && props.selectedTag !== props.buttonTags) {
    return null;
  }

  return (
    <div>
      <div className="button-card" onClick={() => setPopupVisible(true)}>
        <div className="button-card__icon">
          <i class="fa-sharp fa-regular fa-folder-open"></i>
          <div />
        </div>
        <div className="button-card__title">Create Group</div>
        {props.generateButtonTags()}
      </div>

      {isPopupVisible && (
        <div
          ref={popupRef}
          className="mogrt-group__popup"
          onClick={(e) => {
            if (e.target === popupRef.current) {
              setSelectedIndices([]);
            }
          }}
        >
          <div className="mogrt-group__nav">
            <div
              className={`mogrt-group__nav-item ${
                isDisabled ? "disabled" : ""
              }`}
              onClick={groupItems}
            >
              <i class="fa-regular fa-folder-plus"></i>
              <span className="render-group__button-text">Add Group</span>
            </div>
            {process.env.NODE_ENV === "development" && (
              <div
                className="mogrt_group__nav_item"
                onClick={() => getFormattedData()}
              >
                <i class="fa-regular fa-database"></i>
                <span className="render-group__button-text">Get Data</span>
              </div>
            )}
            <div
              className="mogrt-group__nav-item"
              onClick={() => autoSortAndGroup()}
            >
              <i class="fa-regular fa-bars-sort"></i>
              <span className="render-group__button-text">Auto Sort</span>
            </div>
            <div
              className="mogrt-group__nav-item"
              onClick={() => collapseGroups(true)}
            >
              <i class="fa-regular fa-toggle-on"></i>
              <span className="render-group__button-text">Open Groups</span>
            </div>
            <div
              className="mogrt-group__nav-item"
              onClick={() => collapseGroups(false)}
            >
              <i class="fa-solid fa-toggle-off"></i>
              <span className="render-group__button-text">Close Groups</span>
            </div>
            {/* {dataFilePath && (
              <div
                className="mogrt-group__nav-item"
                onClick={() => deleteDataFile(dataFilePath)}
              >
                <i className="fa-regular fa-trash"></i>
                <span className="render-group__button-text">Delete Data</span>
              </div>
            )} */}
            <div
              className="mogrt-group__nav-item"
              onClick={() => {
                updateMogrt();
                setPopupVisible(false);
                setItems([]);
              }}
            >
              <i class="fa-regular fa-file-export"></i>
              <span className="render-group__button-text">Export</span>
            </div>
            <div
              className="mogrt-group__popup-close mogrt-group__nav-item"
              onClick={() => {
                setPopupVisible(false);
                setItems([]);
              }}
            >
              <i class="fa-regular fa-circle-xmark"></i>
              <span className="render-group__button-text">Close</span>
            </div>
          </div>

          <DndProvider backend={HTML5Backend}>
            {items.map((item) => {
              if (!item) return null;
              if (item.type === "item") {
                return (
                  <DraggableItem
                    key={item.id}
                    item={item}
                    id={item.id} // передача ID вместо индекса
                    moveItem={moveItem}
                    isSelected={selectedIndices.includes(item.id)} // Используйте ID, чтобы проверить, выбран ли элемент
                    onSelect={handleSelect}
                    updateItemProperty={updateItemProperty}
                  />
                );
              } else if (item.type === "group") {
                return (
                  <DraggableGroup
                    key={item.id}
                    item={item}
                    id={item.id} // передача ID вместо индекса
                    moveItem={moveItem}
                    selectedIndices={selectedIndices} // нужно также изменить логику selectedIndices на основе ID
                    toggleGroup={toggleGroup}
                    onSelect={handleSelect}
                    deleteGroup={deleteGroup}
                    updateItemProperty={updateItemProperty}
                  />
                );
              }
              return null;
            })}
          </DndProvider>
        </div>
      )}
    </div>
  );
}

export default MogrtGroup;
